<template>
  <div class="accumplished-non-operating-time">
    <div class="non-operating-table-filter">
      <select name="mid" class="filter__mid filter__item" v-model="select_mid">
        <option value="%" >전체</option>
        <option v-for="mid in mids" :key="mid.id" :value="mid.id">{{mid.name}}</option>
      </select>
      <v-btn 
        class="filter-search-btn filter-fn-btn rounded-0"
        :class="{ 'mac': is_mac, }"
        @click="search"
        depressed x-small
      >검색</v-btn>
    </div>
    <div id="content" class="non-operating-content">
      <v-data-table
        class="non-operating-table"
        :class="{ 'mac': is_mac, }"
        :items="data"
        :headers="header"
        :height="height"
        :items-per-page="-1"
        :disable-pagination="true"
        :sort-by.sync="sort.sort_by"
        :sort-desc.sync="sort.sort_desc"
        fixed-header hide-default-footer hide-default-header
      >

        <template v-slot:header="{props}">
          <thead class="v-data-table-header custom-header" :class="{ 'mac': is_mac, }">
            <tr>
              <th
                v-for="head in props.headers"
                :key="head.name"
                class="header"
                :style="{ 
                  'width': head.width, 
                  'text-align': head.align, 
                }"
              >
                <span class="header-text" >{{ head.text }}</span>
                <v-btn 
                  v-if="head.sortable"
                  class="header__sort-btn rounded-0" 
                  :class="{ 
                    'sort--active': sort.sort_by == head.value,
                    'sort--desc': sort.sort_desc,
                  }"
                  :ripple="false"
                  @click="tableSort(head.value)"
                  fab x-small text plain
                >
                  <v-icon size="12">$sort</v-icon>
                </v-btn>
              </th>
            </tr>
          </thead>
        </template>

      </v-data-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
export default {
  data() {
    return {
      data: [
      ],
      mids:[],
      select_mid:null,
      header: [
        {
          text: '호기',
          value: 'machine_no',
          align: 'left',
          sortable: true,
          width: '16%',
        },
        {
          text: '기계명',
          value: 'mid',
          align: 'left',
          sortable: true, 
          width: '16%',
        },
        {
          text: '공정명',
          value: 'process',
          align: 'left',
          sortable: true, 
          width: '27%',
        },
        {
          text: '완료 예정 시각',
          value: 'lot_endtime',
          align: 'left',
          sortable: true, 
          width: '23%',
        },
        {
          text: '비가동 시간',
          value: 'non_operating',
          align: 'left',
          sortable: true, 
          width: '18%',
        },
      ],
      sort: {
        sort_by: '',
        sort_desc: false,
      },
      height: 500,
    };
  },
  computed: {
    is_mac() {
      return this.$is_mac;
    },
  },
  methods: {
    getContentHeight() {
      const content = document.getElementById('content');
      const hei = parseInt(getComputedStyle(content).height);
      this.height = hei - 5;
    },
    animationFrame(cb) {
      let rAftimeout = null;
      return function() {
        if( rAftimeout ) {
          window.cancelAnimationFrame(rAftimeout);
        }
        rAftimeout = window.requestAnimationFrame(function() { cb(); });
      }
    },
    tableSort(value) {
      const sort = this.sort;
      sort.sort_by = value;
      sort.sort_desc = !sort.sort_desc;
    },
    programName(value){
      if(value != undefined){
        let char
        if(value.indexOf('(') != -1){
          char = value.split('(')[1];
          char = char.replace(')', '');
        }else{
          char = value ;
        }
        return char;
      }

    },
    getData(){
      var map = {mkey: this.select_mid}
      this.$http.APS.post('/process/',map).then(r=>{
        for(let i = 0; i < r.data.length; i++){
          r.data[i].start_ymdt = this.dateFormatter(r.data[i].start_ymdt);
          r.data[i].lot_endtime = this.dateFormatter(r.data[i].lot_endtime);
          r.data[i].non_operating =this.timeFormmater(moment(r.data[i].lot_endtime).diff(r.data[i].start_ymdt,'seconds')- r.data[i].active) ;
          r.data[i].process = this.programName(r.data[i].process);
        }
        this.data=r.data;
      })
    },
    search(){
      this.getData();
    },
    getMid(){
      this.$http.APS.get('/idle/key').then(r=>{
        this.mids = r.data;
      })
    },
    dateFormatter(v){
      if(v) v = v.substr(0,19).replace('T',' ');
      return v;
    },
    timeFormmater(v){
      var ment =null;
      if(v){
        var hour = parseInt(v/3600);
        var min = parseInt((v%3600)/60);
        var sec = parseInt(v%60);
        ment = hour+'시간 '+min+'분 '+sec+'초';
      }
      
      return ment; 
     
    }  
  },
  mounted() {
    this.select_mid = '%';
    this.getContentHeight();
    this.getMid();
    this.getData();
    window.addEventListener('resize', this.animationFrame(this.getContentHeight));
  },
  destroyed() {
    document.removeEventListener('resize', this.animationFrame);
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/global.scss';

$table-row-padding: 6%;

  .accumplished-non-operating-time {
    @extend .flex_column;

    justify-content: stretch;
    height: 100%;

    .non-operating-table-filter {
      @extend .common-filter;

      width: 100%;
      flex: 0 0 auto;
      justify-content: flex-end;
      padding: 32px 60px;

      .filter__mid {
        @extend .common-select;
      }
      .filter-search-btn {
        width: 70px;
        height: 40px;

        &.mac {
          padding-top: 3px;
        }
      }
    }

    .non-operating-content {
      width: 100%;
      flex: 1 1 0;
    }
    ::v-deep .non-operating-table {
      @extend .data-table--skyblue-theme;

      thead {
        th {

          &:first-child {
            padding-left: $table-row-padding !important;
          }
          &:last-child {
            // padding-right: $table-row-padding !important;
          }
        }
      }

      tbody {

        td {

          &:first-child {
            padding-left: $table-row-padding !important;

          }
          &:last-child {
            // padding-right: $table-row-padding !important;

          }
        }
      }
    }
  }
</style>